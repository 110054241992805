import Vue from 'vue';
import VueRouter from 'vue-router';
import utils from '@/utils';

Vue.use(VueRouter);

// Area de Administracion
const menuAdministration = [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/views/administration/DashBoard'),
	},
	{
		path: '/acm-info',
		name: 'acm-info',
		component: () => import('@/views/administration/AcmInfoPage'),
	},
	{
		path: '/acm-notification',
		name: 'acm-notification',
		component: () => import('@/views/administration/AcmNotificationPage'),
	},
	{
		path: '/file-legal',
		name: 'file-legal',
		component: () => import('@/views/administration/FileLegalPage'),
	},
	{
		path: '/irrigator',
		name: 'irrigator',
		component: () => import('@/views/administration/IrrigatorPage'),
	},
	{
		path: '/watchman',
		name: 'watchman',
		component: () => import('@/views/administration/WatchmanPage'),
	},
	{
		path: '/payments-reported-list',
		name: 'payments-reported',
		component: () =>
			import('@/views/administration/IrrigatorPaymentsReportedPage'),
	},
	{
		path: '/event-list',
		name: 'events',
		component: () => import('@/views/administration/EventBookPage'),
	},
	{
		path: '/limnimeters',
		name: 'limnimeters',
		component: () => import('@/views/administration/LimnimeterPage'),
	},
	{
		path: '/readings',
		name: 'readings',
		component: () => import('@/views/administration/ReadingsPage'),
	},
];

// Administrador de systema
const menuSystemAdmin = [
	{
		path: '/user-list',
		name: 'userlist',
		component: () => import('@/views/systemadmin/UserPage'),
	},
];

// Fusión de rutas
const childrenRoutesUser = menuSystemAdmin.concat(menuAdministration);

const routes = [
	// Public
	{
		path: '/',
		name: 'login',
		component: () => import('@/views/LoginPage'),
	},
	{
		path: '/init',
		name: 'system-user',
		component: () => import('@/views/SystemPage'),
		children: childrenRoutesUser,
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach(async (to, from, next) => {
	/* Si la pagina de origen está vacia, lo envio al dashboard */
	const storageName = process.env.VUE_APP_STORAGE_NAME;

	if (
		!from.name &&
		to.name != 'dashboard' &&
		utils.getItemStorage(storageName)
	) {
		/* Si tenemos un token y la ruta origen está vacia y destino es diferente al dashboard, lo guio al dashboard para garantizar que se cargué en el state los datos que requiero en el sistema */
		next({ name: 'dashboard' });
	} else if (
		!from.name &&
		to.name != 'login' &&
		!utils.getItemStorage(storageName)
	) {
		/* Si no se tiene un token y se quiere ir a otro lugar, se forza al estar en el login */
		next({ name: 'login' });
	} else {
		// El estatus del usuario no ha cambiado, seguimos recibiendo sus datos
		next();
	}
});

export default router;
