import api from '@/plugins/api';
import utils from '@/utils';

const perPage = Number(process.env.VUE_APP_IRRIGATORS_PER_PAGE);

const state = {
	irrigators: [],
	irrigatorsLastVisible: { count: 0, next: 0 },
	noMoreIrrigators: false,

	locations: [],
	channels: [],
	filterOptions: {
		location: null,
		channel: null,
		code: '',
	},

	irrigatorSelected: {},
	irrigatorProfile: {},

	/* Cuotas pendientes */
	irrigatorFees: [],
	irrigatorFeesLastVisible: { count: 0, next: 0 },
	noMoreIrrigatorFees: false,

	/* Cuotas pagadas */
	irrigatorFeesPaid: [],
	irrigatorFeesPaidLastVisible: { count: 0, next: 0 },
	noMoreIrrigatorFeesPaid: false,

	irrigatorPayments: [],
	irrigatorPaymentsLastVisible: { count: 0, next: 0 },
	noMoreIrrigatorPayments: false,
};

/* Para buscar los datos en en Array de channels y locations */
// const selectData = ( arrayData, dataId ) => arrayData.reduce( ( acc, curr ) => curr.id == dataId ? curr : acc, {} );

const actions = {
	downloadDebtIrrigators() {
		this.dispatch('systemNotification/toggleLoading', true);

		api()
			.get(`/irrigators-debt-report?_limit=1000000`, {
				responseType: 'blob',
			})
			.then(res => {
				const type = res.headers['content-type'];
				const url = window.URL.createObjectURL(
					new Blob([res.data], { type: type, encoding: 'UTF-8' })
				);
				const link = document.createElement('a');
				const date = utils.moment().format('YYYY-MM-DD_HH-mm-ss');
				link.href = url;
				link.download = `Reporte-deuda-${date}.xlsx`;
				link.click();
				window.URL.revokeObjectURL(link.href);
			})
			.finally(() => {
				this.dispatch('systemNotification/toggleLoading', false);
			});
	},
	/* Cargar los datos de los regantes. Si es un celadores, ya el endpoint devuelve solo los datos de los regantes que le corresponden a su canales.
	El parametro dataOptions.next debe ser enviado obligatoriamente*/
	loadIrrigators({ state, commit }, dataOptions) {
		console.log(dataOptions)
		/* Defino las constantes de los filtros */
		const filterByChannel = state.filterOptions.channel || '',
			filterByWatchman = state.filterOptions.watchman || '',
			filterByIrrigator = state.filterOptions.code || '',
			filterByPayer = state.filterOptions.slow_payer || '',
			limitItems = state.filterOptions.perPage || perPage;

		return new Promise(resolve => {
			let query = '';

			/* Si hay algun parametro para filtrar en el state, se arma el query para la peticion */
			if (
				filterByChannel.length ||
				filterByIrrigator.length ||
				filterByWatchman.length ||
				filterByPayer.length
			) {
				if (filterByPayer.length)
					query += `&slow_payer=${filterByPayer}`;

				if (filterByChannel.length)
					query += `&channel=${filterByChannel}`;

				if (filterByIrrigator.length)
					query += `&_where[_or][0][code_contains]=${filterByIrrigator}&_where[_or][1][name_contains]=${filterByIrrigator}&_where[_or][2][rut_contains]=${filterByIrrigator}`;

				if (filterByWatchman.length)
					query += `&watchman=${filterByWatchman}`;
			}

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.get(
					`/irrigators?_sort=name:asc&_start=${dataOptions.next}&_limit=${limitItems}${query}`
				)
				.then(async res => {
					if (dataOptions.next) {
						/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
						const data = state.irrigators.concat(res.data);
						commit('setIrrigators', data);
					} else {
						/* Si next no esta definido, seteo el contador de registros y el state de resultados */
						dataOptions.count = await api().get(
							`/irrigators/count?${query}`
						);
						dataOptions.count = dataOptions.count.data;
						commit('setIrrigators', res.data);
					}

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						commit('setNoMoreIrrigators', true);
					}

					/* Guardo en el state los parametros de la paginacion */
					commit('setIrrigatorsLastVisible', {
						count: dataOptions.count,
						next: dataOptions.next,
					});

					resolve();
				})
				.finally(() => {
					this.dispatch('systemNotification/toggleLoading', false);
				});
		});
	},

	refreshIrrigators({ dispatch, commit }) {
		commit('setIrrigators', []);
		commit('setIrrigatorsLastVisible', { count: 0, next: 0 });
		commit('setNoMoreIrrigators', false);

		dispatch('loadIrrigators', { count: 0, next: 0 });
	},

	/* Cargar la lista completa de canales para los filtros */
	loadChannels({ commit }) {
		/* ID de los documentos de los canales, son los codigos en base64, 
		por recomendacion de firebase, de no usar ID consecutivos */
		return new Promise((resolve, reject) => {
			api()
				.get(`/channels?_sort=name:asc&_limit=200`)
				.then(res => {
					commit('setChannels', res.data);
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	loadLocations({ commit }) {
		/* ID de los documentos de los localidades, son los codigos en base64, 
		por recomendacion de firebase, de no usar ID consecutivos */

		return new Promise((resolve, reject) => {
			api()
				.get(`/locations?_sort=name:asc`)
				.then(res => {
					commit('setLocations', res.data);
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	updateFilterOptions({ commit, dispatch }, data) {
		commit('setFilterOptions', data);
		dispatch('refreshIrrigators');
	},

	resetFilterOptions({ commit, dispatch }) {
		commit('setFilterOptions', {
			location: null,
			channel: null,
			code: '',
		});
		dispatch('refreshIrrigators');
	},

	/* Limpio las variables del filtro y los regantes */
	clearIrrigators({ commit }) {
		commit('setFilterOptions', {
			location: null,
			channel: null,
			code: '',
		});
		commit('setIrrigators', []);
	},

	totalIrrigator(context, data) {
		const options = data.reduce(
			(acc, curr, index) =>
				(acc += index ? `&channel=${curr}` : `?channel=${curr}`),
			''
		);
		return api().get(`/irrigators/count${options}`);
	},

	/* Regantes asociados a un canal en particular */
	async irrigatorsFromChannel(context, code) {
		const { data } = await api().get(`/irrigators-from-channel/${code}`);
		return data;
	},

	/* Carga los datos y perfil del regante */
	readIrrigatorData({ commit, dispatch }, code) {
		this.dispatch('systemNotification/toggleLoading', true);

		/* Trae los datos del regante */
		api()
			.get(`/irrigators?code=${code}`)
			.then(res => {
				const userData = res.data.length ? res.data[0] : {};

				/* Obtener una lista de los otros codigos que pueden estar relacionados a este regante. Y si cuenta con un usuario para iniciar sesion, se retorna ese valor */
				api()
					.get(`/other-irrigators/${code}`)
					.then(res => {
						userData.user = res.data.user;
						userData.others = res.data.others;
					})
					.finally(() => {
						/* Si tiene perfil lo cargo */
						if (!userData['profile'])
							userData['profile'] = { code };
						commit('setIrrigatorProfile', userData.profile);

						/* Carga los datos del regante */
						let data = utils.deepCopy(userData);
						delete data.profile;
						commit('setIrrigatorSelected', data);

						/* Carga las deudas */
						dispatch('loadIrrigatorFees', { code: code, next: 0 });

						/* Carga las deudas */
						dispatch('loadIrrigatorFeesPaid', {
							code: code,
							next: 0,
						});

						this.dispatch(
							'systemNotification/toggleLoading',
							false
						);
					});
			})
			.catch(err => {
				console.log('Error getting document:', err);
			});
	},

	/* Limpia los datos del perfil del regante en el state */
	unreadIrrigatorData({ commit, dispatch }) {
		commit('setIrrigatorProfile', {});
		commit('setIrrigatorSelected', {});
		dispatch('unloadIrrigatorFees');
		dispatch('unloadIrrigatorFeesPaid');
	},

	/* Actualizar los datos del perfil del regante */
	updateIrrigatorProfile({ commit, state }, profile) {
		return new Promise((resolve, reject) => {
			this.dispatch('systemNotification/toggleLoading', true);
			/* Es un perfil existente, asi que se actualiza */
			if (profile._id) {
				api()
					.put(`/irrigator-profiles/${profile._id}`, profile)
					.then(res => {
						const userData = res.data;

						userData.user = state.irrigatorProfile.user;
						userData.others = state.irrigatorProfile.others;
						commit('setIrrigatorProfile', userData);

						resolve();
					})
					.catch(() => {
						reject();
					})
					.finally(() =>
						this.dispatch('systemNotification/toggleLoading', false)
					);
			} else {
				/* Se crea este registro */
				api()
					.post(`/irrigator-profiles`, profile)
					.then(res => {
						const userData = res.data;

						userData.user = state.irrigatorProfile.user;
						userData.others = state.irrigatorProfile.others;
						commit('setIrrigatorProfile', userData);

						resolve();
					})
					.catch(reject)
					.finally(() =>
						this.dispatch('systemNotification/toggleLoading', false)
					);
			}
		});
	},

	/* Esta informacion se carga desde el el perfil del usuario */
	loadIrrigatorFees({ commit, state }, dataOptions) {
		api()
			.get(
				`/irrigator-fees?_sort=expiration:desc&code=${dataOptions.code}&_start=${dataOptions.next}&_limit=${perPage}`
			)
			.then(async res => {
				if (dataOptions.next) {
					/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
					const data = state.irrigatorFees.concat(res.data);
					commit('setIrrigatorFees', data);
				} else {
					/* Si next no esta definido, seteo el contador de registros y el state de resultados */
					dataOptions.count = await api().get(
						`/irrigator-fees/count?code=${dataOptions.code}`
					);
					dataOptions.count = dataOptions.count.data;
					commit('setIrrigatorFees', res.data);
				}

				/* Incremento el parametro next */
				dataOptions.next += perPage;

				/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
				if (dataOptions.next >= dataOptions.count) {
					dataOptions.next = dataOptions.count;
					commit('setNoMoreIrrigatorFees', true);
				}

				/* Guardo en el state los parametros de la paginacion */
				commit('setIrrigatorFeesLastVisible', {
					count: dataOptions.count,
					code: dataOptions.code,
					next: dataOptions.next,
				});
			})
			.catch(err => {
				console.log('Error getting document:', err);
			});
	},

	unloadIrrigatorFees({ commit }) {
		commit('setIrrigatorFees', []);
		commit('setIrrigatorFeesLastVisible', {});
		commit('setNoMoreIrrigatorFees', false);
	},

	/* Esta informacion se carga desde el el perfil del usuario */
	loadIrrigatorFeesPaid({ commit, state }, dataOptions) {
		api()
			.get(
				`/irrigator-fees-paids?_sort=expiration:desc&code=${dataOptions.code}&_start=${dataOptions.next}&_limit=${perPage}`
			)
			.then(async res => {
				if (dataOptions.next) {
					/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
					const data = state.irrigatorFeesPaid.concat(res.data);
					commit('setIrrigatorFeesPaid', data);
				} else {
					/* Si next no esta definido, seteo el contador de registros y el state de resultados */
					dataOptions.count = await api().get(
						`/irrigator-fees-paids/count?code=${dataOptions.code}`
					);
					dataOptions.count = dataOptions.count.data;
					commit('setIrrigatorFeesPaid', res.data);
				}

				/* Incremento el parametro next */
				dataOptions.next += perPage;

				/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
				if (dataOptions.next >= dataOptions.count) {
					dataOptions.next = dataOptions.count;
					commit('setNoMoreIrrigatorFeesPaid', true);
				}

				/* Guardo en el state los parametros de la paginacion */
				commit('setIrrigatorFeesPaidLastVisible', {
					count: dataOptions.count,
					code: dataOptions.code,
					next: dataOptions.next,
				});
			})
			.catch(err => {
				console.log('Error getting document:', err);
			});
	},

	unloadIrrigatorFeesPaid({ commit }) {
		commit('setIrrigatorFeesPaid', []);
		commit('setIrrigatorFeesPaidLastVisible', {});
		commit('setNoMoreIrrigatorFeesPaid', false);
	},

	loadIrrigatorPayments({ commit, state }, dataOptions) {
		return new Promise((resolve, reject) => {
			let query = '';
			if (dataOptions.code) query = `&code=${dataOptions.code}`;
			api()
				.get(
					`/irrigator-payments-reporteds?_sort=createdAt:desc&_start=${dataOptions.next}&_limit=${perPage}${query}`
				)
				.then(async res => {
					if (dataOptions.next) {
						/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
						const data = state.irrigatorPayments.concat(res.data);
						commit('setIrrigatorPayments', data);
					} else {
						/* Si next no esta definido, seteo el contador de registros y el state de resultados */
						dataOptions.count = await api().get(
							`/irrigator-payments-reporteds/count`
						);
						dataOptions.count = dataOptions.count.data;
						commit('setIrrigatorPayments', res.data);
					}

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						commit('setNoMoreIrrigatorPayments', true);
					}

					/* Guardo en el state los parametros de la paginacion */
					commit('setIrrigatorPaymentsLastVisible', {
						count: dataOptions.count,
						next: dataOptions.next,
					});

					resolve();
				})
				.catch(reject);
		});
	},

	/* Lista de codigos de regantes asignados ya a un celador */
	irrigatorsAssigned() {
		return new Promise((resolve, reject) => {
			api()
				.get(`/irrigators-assigned`)
				.then(async res => {
					resolve(res.data);
				})
				.catch(reject);
		});
	},
};

const mutations = {
	setIrrigators(state, data) {
		state.irrigators = data;
	},

	setIrrigatorsLastVisible(state, data) {
		state.irrigatorsLastVisible = data;
	},

	setLocations(state, data) {
		state.locations = data;
	},

	setChannels(state, data) {
		state.channels = data;
	},

	setFilterOptions(state, data) {
		state.filterOptions = data;
	},

	setNoMoreIrrigators(state, data) {
		state.noMoreIrrigators = data;
	},

	setIrrigatorSelected(state, data) {
		state.irrigatorSelected = data;
	},

	setIrrigatorProfile(state, data) {
		state.irrigatorProfile = data;
	},

	/* Cuotas pendientes */
	setIrrigatorFees(state, data) {
		state.irrigatorFees = data;
	},

	setIrrigatorFeesLastVisible(state, data) {
		state.irrigatorFeesLastVisible = data;
	},

	setNoMoreIrrigatorFees(state, data) {
		state.noMoreIrrigatorFees = data;
	},

	/* Cuotas pagadas */
	setIrrigatorFeesPaid(state, data) {
		state.irrigatorFeesPaid = data;
	},

	setIrrigatorFeesPaidLastVisible(state, data) {
		state.irrigatorFeesPaidLastVisible = data;
	},

	setNoMoreIrrigatorFeesPaid(state, data) {
		state.noMoreIrrigatorFeesPaid = data;
	},

	setIrrigatorPayments(state, data) {
		state.irrigatorPayments = data;
	},

	setIrrigatorPaymentsLastVisible(state, data) {
		state.irrigatorPaymentsLastVisible = data;
	},

	setNoMoreIrrigatorPayments(state, data) {
		state.noMoreIrrigatorPayments = data;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
