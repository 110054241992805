import api from '@/plugins/api';

const state = {
    readings: [],
    watchmans: []
};

const actions = {
    loadReadings({ commit }) {
        return new Promise(resolve => {
            api().get(`/readings`)
                .then(res => {
                    const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    commit('setReadings', sortedData);
                    resolve();
                })
        })
    },
    watchmanReadings({ commit }) {
        return new Promise(resolve => {
            api().get(`/readings-watchman`).then(res => {
                const watchmans = res.data.watchmans.map(watchman => {
                    return {
                        code: watchman.id,
                        name: watchman.name
                    };
                })
                watchmans.unshift({
                    code: null,
                    name: 'Todos'
                })

                commit('setWatchmansReadings', watchmans)
                resolve()
            })
        })
    }
};

const mutations = {
    setReadings(state, data) {
        state.readings = data
    },
    setWatchmansReadings(state, data) {
        state.watchmans = data
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
