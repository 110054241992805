import api from '@/plugins/api';

const state = {
    limnimeters: []
};

const actions = {
    loadLimnimeters({ commit }) {
        return new Promise(resolve => {
            api().get(`/limnimeters`)
                .then(res => {
                    commit('setLimnimeters', res.data);
                    resolve();
                })
        })
    }
};

const mutations = {
    setLimnimeters(state, data) {
        state.limnimeters = data
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
